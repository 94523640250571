import React, { useEffect, useState } from 'react';
import Slide from './Slide';
import NFTApi from '../api/NFTApi';
import Reveal from 'reveal.js';
import 'reveal.js/dist/reveal.css';
import 'reveal.js/dist/theme/black.css';
import 'reveal.js/plugin/highlight/highlight.js';
import 'reveal.js/plugin/notes/notes.js';
import 'reveal.js/plugin/markdown/markdown.js';
import { getCookieConsentValue } from "react-cookie-consent";
import { logVisitSlideshow } from "../analytics";

const Gallery = () => {
  if (getCookieConsentValue() === 'true') {
    logVisitSlideshow();
  }
  const [owner, setOwner] = useState(null);
  const [nfts, setNfts] = useState([]);
  const [nftsArray, setNftsArray] = useState([]);
  const [ragArray, setRagArray] = useState([]);
  const [dataIsLoaded, setDataIsLoaded] = useState(false);
  const [validNFT, setValidNFT] = useState(false);
  const [isMainPath, setIsMainPath] = useState(false);
  // State variable to track whether the device is in landscape mode
  const [isLandscape, setIsLandscape] = useState(window.innerWidth > window.innerHeight);
  // State variable to track whether the device is mobile
  const [isMobile, setIsMobile] = useState(/Mobi|Android/i.test(navigator.userAgent));

  // RAG contract address
  const RAGContract = "0x21E68422011a330beFdf45b0E45072c947316C95";

  // Function to check orientation
  const checkOrientation = () => {
    if (window.innerWidth > window.innerHeight) {
      setIsLandscape(true);
    } else {
      setIsLandscape(false);
    }
  };

  useEffect(() => {
    // Check orientation when component mounts
    checkOrientation();

    // Listen for window resize events (which include orientation changes)
    window.addEventListener('resize', checkOrientation);

    async function fetchData() {
      try {
        const urlParts = window.location.href.split("://")[1].split("/");

        // If the lenght of urlParts is 1 then the user is on the main path
        // Is the leght is 2 and the second part is / then is on main path                
        if (urlParts.length === 1 || (urlParts.length === 2 && urlParts[1] === "")) {
          setIsMainPath(true);
          setDataIsLoaded(true);
        }
        else {
          var address = urlParts[1].split("?")[0].split("#")[0];
          const [nftData] = await Promise.all([
            NFTApi.getWalletNFTs(address),
          ]);

          setOwner(address);
          setNfts(nftData);

          console.log(nftData.data);
          var nft_array = [];
          var rag_array = [];
          Object.keys(nftData.data).map((e, i) => {
            // Iterate over e and push the elements to nftArray using a for loop
            if (e.toLowerCase() !== RAGContract.toLowerCase()) {
              for (let i = 0; i < nftData.data[e].length; i++) {
                if (nftData.data[e][i].token_id !== undefined) {
                  nft_array.push(nftData.data[e][i]);
                }
              }
            }
            else {
              for (let i = 0; i < nftData.data[e].length; i++) {
                nft_array.push(nftData.data[e][i]);
                rag_array.push(nftData.data[e][i]);
              }
            }
          });

          setNftsArray(nft_array);
          setRagArray(rag_array);

          setValidNFT(true);
          setDataIsLoaded(true);

          if (isLandscape) {
            Reveal.initialize({
              autoSlide: 10000,
              transitionSpeed: 'slow',
              controlsBackArrows: 'faded',
              verticalSlide: isLandscape,
              loop: true, controls: true, progress: true, history: true, center: true, transition: 'convex'
            });
          } else {
            Reveal.initialize({
              autoSlide: 10000,
              transitionSpeed: 'slow',
              controlsBackArrows: 'faded',
              verticalSlide: isLandscape,
              loop: true, controls: true, progress: true, history: true, center: true, transition: 'convex'
            });
          }
        }
      } catch (error) {
        console.log("Error fetching data" + error);
        // Print stack trace
        console.log(error.stack);
        setDataIsLoaded(true);
        setValidNFT(false);
      }
    }

    fetchData();
    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('resize', checkOrientation);
    };
  }, []);

  if (!dataIsLoaded) {
    return <div className="loading-nfts"><h1> Warrior or Mage, your path is being forged. Prepare for the quest ahead.</h1></div>;
  }

  if (!validNFT) {
    return (
      <div className="loading-nfts">
        <h1> Oops! We need your Cronos address for this quest.</h1>
        <h2> Wanna be an early explorer? Get an invite from our Discord base camp! 🚀</h2>
      </div>
    );
  }

  return (
    <>
      <div className="reveal">
        <div className="slides">

          {isLandscape && (
            <p className='NFTOwner'>Cronos Ragdolls NFT Owner:<br /> {owner}</p>
          )}

          {nftsArray.map((nft, index) => (
            nft._media_url &&
            <section key={index} data-background-gradient="linear-gradient(to bottom, #000000, #283b95, #000)">
              <div className={isLandscape ? 'r-hstack' : 'r-vstack'}>
                <div className={isLandscape ? 'r-vstack NFTImage' : 'r-hstack NFTImage'}>
                  <img src={nft._media_url} alt={nft.name} />
                </div>
                {!isLandscape && (
                  <>
                    <p className='NFTName'>{nft.name}</p>
                    <div>
                      <p className='NFTOwner'>Cronos Ragdolls NFT Owner:<br /> {owner}</p>
                    </div>
                  </>
                )}
                <div className={isLandscape ? 'r-vstack NFTInfo' : 'r-hstack NFTInfo'}>
                  {isLandscape && (
                    <>
                      <p className='NFTName'>{nft.name}</p>
                      {nft.description !== nft.name && isLandscape && <p className='NFTDescription'>{nft.description}</p>}
                      {nft.attributes && (
                        <div className='NFTAttributeContainer'>
                          <>
                            {nft.attributes.map((attribute, index) => (
                              <p key={index} className='NFTAttribute'><b>{attribute.trait_type}</b>: {attribute.value}</p>
                            ))}
                          </>
                        </div>
                      )}
                    </>)}
                </div>
              </div>
            </section>
          ))}
        </div>
      </div >
    </>
  );
};

export default Gallery;