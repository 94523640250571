import React, { useEffect } from 'react';
import Gallery from './components/Gallery';
import "./App.css";
import CookieConsent from "react-cookie-consent";
import { initGA, logPageView } from "./analytics";

const App = () => {

  const handleAcceptCookie = () => {
    localStorage.setItem("cookie-consent", "true");
    initGA();
    logPageView();
  };

  const handleDeclineCookie = () => {
    localStorage.setItem("cookie-consent", "false");
    // Optionally, you could also clear GA cookies here.
  };

  useEffect(() => {
    if (localStorage.getItem("cookie-consent") === "true") {
      initGA();
      logPageView();
    }
  }, []);

  return (
    <>
      <CookieConsent
        enableDeclineButton
        onAccept={handleAcceptCookie}
        onDecline={handleDeclineCookie}
        buttonText="Ok, purrfect"
        declineButtonText="No cookies for the cats"
        containerClasses="custom-cookie-consent"
        buttonClasses="custom-cookie-button"
        buttonStyle={{ background: "lightgreen", color: "black" }}
        declineButtonStyle={{ background: "lightgray", color: "black" }}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
      <Gallery />;
    </>
  );
};

export default App;